import http from "./httpService";

const getAllData = async (values) => {
  const fd = new FormData();
  Object.keys(values).map((k) => {
    fd.append(k, values[k]);
  });
  return await http.post(`/get.php`, fd);
};

const getGroups = async (userCred) => {
  const fd = new FormData();
  Object.keys(userCred).map((k) => {
    fd.append(k, userCred[k]);
  });
  return await http.post(`/get-groups.php`, fd);
};

const generateReport = async (obj, reportType) => {
  const queries = prepareQueries(obj);

  return await http.get(`/report/${reportTypes[reportType]}.php${queries}`, {
    responseType: "blob",
  });
};

export default {
  getAllData,
  getGroups,
  generateReport,
};

function prepareQueries(obj) {
  return Object.keys(obj).reduce(
    (acc, current, i, arr) =>
      acc + current + "=" + obj[current] + (arr.length - 1 == i ? "" : "&"),
    "?"
  );
}

const reportTypes = ["consumption", "alarm_report", "filling_up"];
