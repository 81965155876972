import axios from "axios";

import toast from "react-hot-toast";

const HTTP = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // headers: {
  //   common: {
  //     "Access-Control-Allow-Origin": "*",
  //   },
  // },
});

HTTP.interceptors.request.use(
  (config) => {
    // do what you want before every request
    // config.headers["token"] = your token here like sessionStorage.getItem('token')
    return {
      ...config,
      // any other properties
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);
HTTP.interceptors.response.use(null, (error) => {
  //handling error => log it or what ever
  if (
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 500
  ) {
    console.log("this is errors:>", error.response.message);
    if (error.response.status == 401) {
      toast.error("you're not authorized to do like request.");
      // router.replace(`/not-auth?redirectTo=${window.location.pathname}`);
    } else {
      toast.error(error.response.data.Message);
    }
    return Promise.reject(error);
  } else {
    console.log("Loggin the error", error);
  }
});

export default {
  get: HTTP.get,
  post: HTTP.post,
  put: HTTP.put,
  delete: HTTP.delete,
  HTTP,
};
