import React, { useEffect, useState, useRef } from "react";

import { useFormik } from "formik";
import { reportSchema } from "../schemas";
import toast, { Toaster } from "react-hot-toast";
import ReportService from "../Services/ReportService";
import { Loading } from "./LoginPage";

import moment from "moment";
import { Link } from "react-router-dom";

import logoutImg from "../logout.svg";

const periods = [
  {
    label: "one hour",
    value: 1,
  },
  {
    label: "one day",
    value: 24,
  },
  {
    label: "one week",
    value: 168,
  },
  {
    label: "one month",
    value: 720,
  },
  {
    label: "one year",
    value: 8760,
  },
];

export default function ReportPage() {
  const [groups, setGroups] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [customPeriod, setCustomPeriod] = useState(false);
  // const [fromDate, setFromDate] = useState();
  // const [toDate, setToDate] = useState();
  const [loading, setLoading] = useState(false);
  const user_session = sessionStorage.getItem("user_session");
  const user_id = sessionStorage.getItem("user_id");
  const reportForm = useRef();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    /* and other goodies */
  } = useFormik({
    initialValues: {
      eq_id: "",
      sensor: null,
      period: "",
      from: new Date().toISOString().split("T")[0],
      to: new Date().toISOString().split("T")[0],
      rate: "",
    },
    validationSchema: reportSchema,
    onSubmit: async () => {
      console.log("submitting");
      // // call back end and auth user to go to next route
      // const reportResponse = await ReportService.getReport(values);
      // console.log(reportResponse);
      // if (reportResponse && reportResponse.status == 200) {
      //   // toast.success(`welcome ${values.eq_id}`);
      // }
    },
  });

  const getGroups = async () => {
    const groupsResponse = await ReportService.getAllData({
      user_session,
      user_id,
    });
    console.log("this is groupsReponse:>", groupsResponse);
    if (
      groupsResponse &&
      groupsResponse.status == 200 &&
      groupsResponse.data.response.status == "success"
    ) {
      const responseData = groupsResponse.data.response.data;
      setGroups(responseData);
      console.log(groups);
    }
  };
  const getSensors = async (eq_id) => {
    const groupSensors = groups.find((g) => g.eq_id == eq_id).sensors;
    setSensors(groupSensors);
  };

  const generateReport = async (reportType) => {
    if (Object.keys(errors).length && Object.keys(touched).length) {
      toast.error("please enter data correctly");
      return;
    } else if (!Object.keys(errors).length && !Object.keys(touched).length) {
      toast.error("please fill the form");
      return;
    }
    const periodObj = {};
    if (!customPeriod) {
      periodObj.to = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      periodObj.from = moment(subtractHours(values.period)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    } else {
      periodObj.to = moment(new Date(values.to)).format("YYYY-MM-DD HH:mm:ss");
      periodObj.from = moment(new Date(values.from)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }
    setLoading(true);
    const queries = {
      device_id: groups.find((g) => g.eq_id == values.eq_id).sensors[
        values.sensor
      ].device_id,
      sensor_name: groups.find((g) => g.eq_id == values.eq_id).sensors[
        values.sensor
      ].sensor_name,
      to: periodObj.to,
      from: periodObj.from,
      rate: values.rate,
      current_time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    };
    console.log("this is queries", queries);
    const reportResponse = await ReportService.generateReport(
      queries,
      reportType
    );
    if (reportResponse && reportResponse.status == 200) {
      toast.success(`your report is genrating...`);
      console.log("this is reportResponse", reportResponse);
      const file = new Blob([reportResponse.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
      // const fileName = "report";
      // const a = document.createElement("a");
      // a.href = window.URL.createObjectURL(reportResponse.data);
      // a.download = `${fileName}.pdf`;
      // document.body.appendChild(a);
      // a.click();
      // a.remove();
    }
    setLoading(false);
  };

  useEffect(() => {
    getGroups();
  }, []);
  useEffect(() => {
    console.log("from values.period change", values.period);
    setCustomPeriod(values.period == "custom");
  }, [values.period]);
  if (!groups.length)
    return (
      <div className="report-page">
        <div
          style={{
            display: "grid",
            alignContent: "center",
            zIndex: "999999",
          }}
        >
          <Loading />
        </div>
      </div>
    );
  return (
    <div className="report-page">
      <form className="login-form" onSubmit={handleSubmit} ref={reportForm}>
        <h2 className="heading">Generate Reports</h2>
        <div className="form-body">
          <div className="form-group">
            <label htmlFor="groups">Equipment</label>
            <select
              id="groups"
              name="eq_id"
              onChange={(e) => {
                getSensors(e.target.value);
                handleChange(e);
              }}
              onBlur={handleBlur}
              value={values.eq_id}
            >
              <option value="">select equipment</option>
              {groups.map((group) => (
                <option key={group.eq_id} value={group.eq_id}>
                  {group.eq_name}
                </option>
              ))}
            </select>
            {touched.eq_id && errors.eq_id ? (
              <span className="error">{errors.eq_id}</span>
            ) : (
              ""
            )}
          </div>
          {sensors.length ? (
            <div className="form-group">
              <label htmlFor="sensors">
                Sensors of {groups.find((g) => g.eq_id == values.eq_id).eq_name}
              </label>
              <select
                id="sensors"
                name="sensor"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.sensor}
              >
                <option value="">select sensor</option>
                {sensors.map((sensor, i) => (
                  <option
                    key={i}
                    value={i}
                    disabled={sensor.type_name == "Volume" ? false : true}
                  >
                    {sensor.display_name}
                  </option>
                ))}
              </select>
              {touched.sensor && errors.sensor ? (
                <span className="error">{errors.sensor}</span>
              ) : (
                ""
              )}
            </div>
          ) : null}
          <div className="form-group">
            <label htmlFor="period">Period</label>
            <select
              id="period"
              name="period"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.period}
            >
              <option value="">select period</option>
              {periods.map((period) => (
                <option key={period.value} value={period.value}>
                  {period.label}
                </option>
              ))}
              <option value="custom">Custom Period</option>
            </select>
            {touched.period && errors.period ? (
              <span className="error">{errors.period}</span>
            ) : (
              ""
            )}
            {/* <DateTimePicker
              label="Controlled picker"
              value={value}
              onChange={(newValue) => setFromDate(newValue)}
            /> */}
          </div>
          {customPeriod ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "2rem",
              }}
            >
              <div className="form-group date-group">
                <label htmlFor="from">from</label>
                <input
                  type="date"
                  name="from"
                  id="from"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.from}
                />
              </div>
              <div className="form-group date-group">
                <label htmlFor="to">to</label>
                <input
                  type="date"
                  name="to"
                  id="to"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.to}
                />
              </div>
            </div>
          ) : null}
          <div className="form-group">
            <label htmlFor="rate">
              Rate{" "}
              <span style={{ fontSize: ".9rem" }}>
                (one liter diesel cost in $)
              </span>
            </label>
            <input
              type="number"
              id="rate"
              name="rate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.rate}
            />
            {touched.rate && errors.rate ? (
              <span className="error">{errors.rate}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div className="form-action">
            <button
              className="btn"
              disabled={isSubmitting}
              onClick={() => generateReport(2)}
            >
              Filled up
            </button>
            <button
              className="btn"
              disabled={isSubmitting}
              onClick={() => generateReport(0)}
            >
              Consumption
            </button>
            <button
              className="btn"
              disabled={isSubmitting}
              onClick={() => generateReport(1)}
            >
              Alarm
            </button>
          </div>
        )}
      </form>
      <Link
        to="/logout"
        style={{
          position: "absolute",
          bottom: "2rem",
          right: "3rem",
          zIndex: 9999999,
          color: "#fff",
          display: "flex",
          alignItems: "center",
          fontSize: "1.2rem",
        }}
      >
        logout
        <img
          src={logoutImg}
          alt="logout"
          width={15}
          style={{
            margin: "5px",
          }}
        />
      </Link>
      <Toaster />
    </div>
  );
}

function subtractHours(hours) {
  const date = new Date();
  date.setHours(date.getHours() - hours);
  return date;
}
