import React, { useEffect } from "react";
import { useFormik } from "formik";
import { loginSchema } from "../schemas";
import { toast } from "react-hot-toast";
import AuthUserService from "../Services/AuthUserService.js";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const navigate = useNavigate();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    /* and other goodies */
  } = useFormik({
    initialValues: { username: "", password: "" },
    validationSchema: loginSchema,
    onSubmit: async () => {
      console.log("submitting");
      // call back end and auth user to go to next route
      const userResponse = await AuthUserService.authUser(values);
      console.log(userResponse);
      if (
        userResponse &&
        userResponse.status == 200 &&
        userResponse.data.response.status == "success"
      ) {
        toast.success(`welcome ${values.username}`);
        const data = userResponse.data.response.data;
        sessionStorage.setItem("user_session", data.session);
        sessionStorage.setItem("user_id", data.uid);
        navigate("/report");
      }
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem("user_session") &&
      sessionStorage.getItem("user_id")
    ) {
      navigate("/report");
    }
  }, []);

  return (
    <div className="login-page">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2 className="heading">login</h2>
        <div className="form-body">
          <div className="form-group">
            <label htmlFor="username">User Name</label>
            <input
              type="text"
              id="username"
              name="username"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
            />
            <span className="error">{errors.username}</span>
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              onChange={handleChange}
              onBlur={handleBlur}
              name="password"
              value={values.password}
            />
            <span className="error">{errors.password}</span>
          </div>
        </div>

        <button className="btn" type="submit" disabled={isSubmitting}>
          {isSubmitting ? <Loading /> : "submit"}
        </button>
      </form>
    </div>
  );
}

export function Loading() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
}
