import http from "./httpService";

const authUser = async ({ username, password }) => {
  const fd = new FormData();
  fd.append("username", username);
  fd.append("password", password);
  return await http.post(`/login.php`, fd);
};

export default {
  authUser,
};
