import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import ReportPage from "./pages/ReportPage";
import NotFound from "./pages/NotFound";
import Logout from "./pages/Logout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/logout" element={<Logout />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/report" element={<ReportPage />} />
        <Route path="*" element={<LoginPage />} />
        {/* <Route path="/" element={<AuthRoute />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
