import * as yup from "yup";

export const loginSchema = yup.object({
  username: yup.string().required().label("Username"),
  password: yup.string().required().label("Password"),
});

export const reportSchema = yup.object({
  eq_id: yup.string().required("please select a Group"),
  sensor: yup.string().required("please choose a Sensor"),
  period: yup.string().required("please select a period"),
  rate: yup.number().required("Type the Rate please"),
});
